import cx from 'classnames';
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { getPageType } from '../../../../redux/page';
import { selectIsMobileView } from '../../../../utils';
import Constants from '../constants';
import MultiLevel from './MultiLevel';
import SingleLevel from './SingleLevel';
import Styles from './styles.scss';

interface StateProps {
	isMobileSize: boolean;
	page: Page.PageType;
}

export interface OwnProps {
	isHeader?: boolean;
	levels?: Breadcrumbs.Level[];
	className?: string;
}

type Props = OwnProps & StateProps;

const BreadcrumbsWrapper: FC<Props> = (props) => {
	const { isHeader, isMobileSize, levels, page } = props;
	const secondFromTheLast = levels[levels.length - 2];
	const [isMobileFooter, setIsMobileFooter] = useState<boolean>(
		isMobileSize && !isHeader,
	);

	const isListingsPage = useAppSelector(
		(state) => state.page.pageType === 'city',
	);

	const { track } = useAnalyticsContext();

	useEffect(() => {
		setIsMobileFooter(isMobileSize && !isHeader);
	}, [isMobileSize]);

	const handleClick = (analytics: Breadcrumbs.Analytics) => {
		const properties =
			page === 'city'
				? { ...analytics, sourcePage: Constants.SOURCE_PAGE.CATEGORY_CITY }
				: analytics;

		track({
			event: Constants.ANALYTICS.BREADCRUMB_INTERACTION,
			properties,
		});

		if (analytics.sourcePage === Constants.SOURCE_PAGE.CATEGORY_CITY) {
			track({
				event: Constants.ANALYTICS.CLICK_THROUGH_TO_VENDOR_CATEGORY,
				properties,
			});
		}
	};
	const className = props.className || '';
	return (
		<div
			className={cx(
				className,
				Styles.breadcrumbs,
				Styles.breadcrumbsSemanticHeader,
				{ [Styles.breadcrumbsHeader]: isHeader },
				{ [Styles.breadcrumbsMobileFooter]: isMobileFooter },
				{ [Styles.breadcrumbsListingsPage]: isListingsPage },
			)}
		>
			{isHeader ? (
				<SingleLevel level={secondFromTheLast} clickHandler={handleClick} />
			) : (
				<MultiLevel levels={levels} clickHandler={handleClick} />
			)}
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	isMobileSize: selectIsMobileView(state),
	page: getPageType(state),
});

export default connect(mapStateToProps)(BreadcrumbsWrapper);
